@import url('https://fonts.googleapis.com/css2?family=Newsreader:ital,opsz,wght@0,6..72,200..800;1,6..72,200..800&family=Teko:wght@300..700&display=swap');
:root {
    --blue: #3295ff;
    /* --background: whitesmoke; */
    --background: #daeaf7;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.teko-h3,.teko-h2 {
    font-family: "Teko", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
}
.teko-h2 {
    font-size: 3em;
}
.teko-h3 {
    font-size: 2em;
    line-height: 1;
}

.App {
    position: relative;
    height: 100svh;
    width: 100svw;
    overflow: hidden;

    background: var(--background);
    color: black;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3%;
    

    font-family: Newsreader, sans-serif
    
}
#Title {
    display: grid;
    grid-template-columns: 50% 50%;
    width: 80%;
    border-bottom: var(--blue) 5px solid;
    justify-items: center;
    margin-top: 2svh;
}

#Summary {
    width: 70%;
    min-height: 40%;
    display: flex;
    grid-template-columns: 49% 2% 49%;
    grid-template-rows: auto;
    flex-wrap: wrap;
    padding-bottom: 1em;
}
#Education, #Experience {
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 50%;
    min-width: 400px;
    /* margin: 1em; */
}
.summary-title {
    align-self: center;
    font-size: 3em;
}
.degree {
    display: flex;
    flex-direction: column;
    gap: 0.1em;
    margin-inline: 10%;
}
.degree-desc, .degree-title {
    padding: 0;
    margin: 0;
}

i, .pizzaz {
    display: inline-block;
    background: red;
    padding-right: 5px;
    background: linear-gradient(90deg, #0855b1, #0e5cb6, #1463bb, #1b6abe, #2371c2, #2b78c5, #337fc7, #4085c5);
    background-clip: text;
    color: transparent;
    font-weight: 900;
    /* filter: brightness(90%); */
}

#AboutMe {
    font-size: 2.5em;
    width: 50%;
    text-align: center;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
    gap: 0.2em;
}